import {type LoaderFunctionArgs, json} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import {AlignmentEnum, ColorSchemeEnum, Theme} from '@/enums';
import type {Handle} from '@/types';
import AnnotatedImage from '@/components/pos/section/AnnotatedImage/AnnotatedImage';
import PosSectionHeader from '@/components/pos/section/SectionHeader/SectionHeader';
import PosConversion from '@/components/pos/section/Conversion/Conversion';
import {ProductDemoVariant} from '@/components/brochureV2/section/ProductDemo/types';
import PosProductDemo from '@/components/pos/section/ProductDemo/ProductDemo';
import Cards from '@/components/brochureV2/section/Cards/Cards';
import PosOutcomes from '@/components/pos/section/Outcomes/Outcomes';
import {OutcomesVariant} from '@/components/brochureV2/section/Outcomes';
import PosFAQ from '@/components/pos/section/FAQ/FAQ';
import {
  isRetailDemoVideoAvailable,
  isRetailSalesAvailable,
  isRetailPosTerminalAvailable,
  isRetailPosGoAvailable,
  isRetailWisePad3CardReaderAvailable,
  isRetailHardwareAndPaymentsAvailable,
  isRetailBlogAvailable,
} from '@/pages/shopify.com/($locale)/pos/posLocaleRules';
import PosHeroFullBleed from '@/components/pos/section/Hero/HeroFullBleed';
import type {Image} from '@/utils/image';
import {useTranslations} from '@/hooks/useTranslations';
import ShopifyPOSSchema from '@/components/pos/schema/schema';
import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import Section from '@/components/base/layouts/Section/Section';
import FullWidthCard from '@/components/base/modules/FullWidthCard/FullWidthCard';
import Typography from '@/components/base/elements/Typography/Typography';
import Button from '@/components/base/elements/Button/Button';
import {twMerge} from '@/stylesheets/twMerge';
import {retailSubscriptionIncentivePrice} from '@/pages/shopify.com/($locale)/pos/retailSubscriptionIncentivePrice';
import {normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import {fetchPlanSummaries} from '@/utils/server/loader/plans/planSummariesLoader.server';
import SideBySideList from '@/components/base/sections/SideBySideList/SideBySideList';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import HardwareImage from '@/components/base/elements/Image/Image';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Card from '@/components/base/modules/Card/Card';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import {useFlag} from '@/hooks/useFlag';
import {FLAG_RSI_RETAIL_PLAN_INCENTIVE} from '@/flags';
import {retailSpRateIncentiveDetails} from '@/pages/shopify.com/($locale)/pos/retailSpRateIncentiveDetails';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';

export const handle: Handle = {
  theme: Theme.Pos,
  parentTheme: Theme.BrochureV2,
  signupTypes: ['pos'],
  incentivePromo: true,
};

export const loader = async (args: LoaderFunctionArgs) => {
  const {planSummaries} = await fetchPlanSummaries(args);
  const summaries = normalizeServerPlanSummaryData(planSummaries, {
    includePlusInGrid: true,
  });
  return json({
    planSummaries: summaries,
  });
};

// TODO: Refactor so logic around what should be rendered based on locale is done in loader
// eslint-disable-next-line complexity
export default function Overview() {
  const loaderData = useLoaderData<typeof loader>();
  const {isSPRateIncentive: isSPRateIncentiveVariant} = useRetailIncentive();
  const enablePosIncentive = useFlag(FLAG_RSI_RETAIL_PLAN_INCENTIVE);
  const serverPlansData = loaderData.planSummaries || undefined;
  const retailSpRateIncentive = retailSpRateIncentiveDetails(serverPlansData);
  const {
    t,
    images,
    site,
    localeImage,
    localizePath,
    values,
    settings,
    localeData,
  } = useTranslations();
  const showCardReaderDiscountIncentive = site.countryCode === 'US';
  const posGo = ['en', 'es'];
  const wisePadEng = ['en-GB', 'en-IE', 'en-AU', 'en-NZ', 'en-CA', 'fr-CA'];
  const wisePadIntl = [
    'de',
    'nl',
    'es-ES',
    'it',
    'de-BE',
    'nl-BE',
    'fr-BE',
    'en-BE',
    'da',
    'en-DK',
  ];
  const singapore = ['en-SG'];

  const withinUSOrCA =
    (site.countryCode === 'US' || site.countryCode === 'CA') &&
    (site.languageCode === 'en' || site.languageCode === 'fr');

  const localizedAltText = () => {
    if (['en'].includes(site.locale)) {
      return t('productDemo3.image.alt_cardReader');
    } else if (['es'].includes(site.locale)) {
      return t('productDemo3.image.alt_posGo');
    } else if (isRetailWisePad3CardReaderAvailable(site.locale)) {
      return t('productDemo3.image.alt_wisepad3');
    } else {
      return '';
    }
  };

  const secHeadMaxWidth = 'max-w-3xl';
  const posTerminalHeroImagePositioning =
    '[&>div]:bg-[center_20rem] sm:[&>div]:bg-[center_10rem] md:[&>div]:bg-[center_-4rem]';
  const nonPosTerminalHeroImagePositioning =
    '[&>div]:bg-[center_17rem] sm:[&>div]:bg-[center_14rem] md:[&>div]:bg-[center_4rem]';

  const incentiveDetails = (() => {
    if (isSPRateIncentiveVariant) {
      return retailSpRateIncentive;
    } else if (enablePosIncentive) {
      return {price: retailSubscriptionIncentivePrice(site, serverPlansData)};
    }
    return undefined;
  })();

  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();

  return (
    <>
      <PosHeroFullBleed
        {...t('hero')}
        colorScheme={ColorSchemeEnum.PosLight}
        images={[{srcSet: localeImage(images.hero.image.default)}]}
        className={twMerge(
          '[&_h1]:text-t7 !pt-0',
          isRetailPosTerminalAvailable(site.locale)
            ? posTerminalHeroImagePositioning
            : nonPosTerminalHeroImagePositioning,
        )}
        mobileOverlayClassName="bg-pos-overview-hero-base sm:bg-pos-overview-hero-sm md:bg-pos-overview-hero-lg"
        secondaryLink={
          isRetailSalesAvailable(site.locale)
            ? {
                text: t('hero.secondaryLink.text'),
                url: '#contact-sales',
              }
            : null
        }
        link={
          isRetailUnifiedFunnelExperiment
            ? {
                text: t('pos:components.contactSalesCta'),
                url: '#contact-sales',
              }
            : null
        }
        disclaimerHtml={t('hero.signup.disclaimerHtml')}
        showSignup={!isRetailUnifiedFunnelExperiment}
        bleedBehindHeader={true}
        incentiveDetails={incentiveDetails}
        showCardReaderDiscountIncentive={showCardReaderDiscountIncentive}
      />
      <Section
        mode="light"
        className="bg-pos-warm-gray"
        topSpacing="2xl"
        bottomSpacing="2xl"
      >
        <LogoGroup
          className="text-shade-70 fill-shade-70 border-y-2 border-opacity-[15%] gap-x-2xl"
          logos={(
            localeData(images.logoSoup.logosByLocale) as Array<string>
          ).map((item: any) => ({
            alt: settings(`images.logoSoup.logos.${item}.altText`),
            src: settings(`images.logoSoup.logos.${item}.src`),
            className: 'w-full h-full max-h-16 grayscale brightness-0',
          }))}
        />
      </Section>
      <PosSectionHeader
        {...t('sectionHeader1')}
        colorScheme={ColorSchemeEnum.PosWarmGray}
        alignment={AlignmentEnum.Left}
        className="[&_h2]:text-t1"
        headingWrapperClass={secHeadMaxWidth}
        subheadingWrapperClass={secHeadMaxWidth}
      />
      {[...posGo].includes(site.locale) && (
        <AnnotatedImage
          desktopImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.desktopImage.image.default,
            ),
            alt: t('annotatedImage.desktopImage.alt.default'),
          }}
          mobileImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.mobileImage.image.default,
            ),
            alt: t('annotatedImage.mobileImage.alt.default'),
          }}
          annotations={[
            {
              id: 'annotation-1',
              heading: t('annotatedImage.annotations.annotation1.heading'),
              content: t('annotatedImage.annotations.annotation1.content'),
              positionClasses:
                'top-[3%] right-[79%] sm:top-[15%] sm:right-auto md:right-[76.5%]',
            },
            {
              id: 'annotation-2',
              heading: t(
                'annotatedImage.annotations.annotation2.heading.default',
              ),
              content: t(
                'annotatedImage.annotations.annotation2.content.default',
              ),
              positionClasses:
                'top-[27%] right-[14%] sm:top-auto sm:bottom-[-20px] sm:right-[54%]',
            },
            {
              id: 'annotation-3',
              heading: t('annotatedImage.annotations.annotation3.heading'),
              content: t('annotatedImage.annotations.annotation3.content'),
              positionClasses:
                'top-[67%] right-[52%] sm:top-[20%] sm:right-0 md:top-[15%] md:right-[20%]',
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
        />
      )}
      {[...wisePadEng, ...wisePadIntl, ...singapore].includes(site.locale) && (
        <AnnotatedImage
          desktopImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.desktopImage.image.other,
            ),
            alt: t('annotatedImage.desktopImage.alt.default'),
          }}
          mobileImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.mobileImage.image.other,
            ),
            alt: t('annotatedImage.mobileImage.alt.default'),
          }}
          annotations={[
            {
              id: 'annotation-1',
              heading: t('annotatedImage.annotations.annotation1.heading'),
              content: t('annotatedImage.annotations.annotation1.content'),
              positionClasses:
                'top-[3%] right-[79%] sm:top-[15%] sm:right-auto md:right-[76.5%]',
            },
            {
              id: 'annotation-2',
              heading: t(
                'annotatedImage.annotations.annotation2.heading.default',
              ),
              content: t(
                'annotatedImage.annotations.annotation2.content.default',
              ),
              positionClasses:
                'top-[27%] right-[14%] sm:top-auto sm:bottom-[-20px] sm:right-[54%]',
            },
            {
              id: 'annotation-3',
              heading: t('annotatedImage.annotations.annotation3.heading'),
              content: t('annotatedImage.annotations.annotation3.content'),
              positionClasses:
                'top-[67%] right-[52%] sm:top-[20%] sm:right-0 md:top-[15%] md:right-[20%]',
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
        />
      )}
      {!isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <AnnotatedImage
          desktopImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.desktopImage.image.other,
            ),
            alt: t('annotatedImage.desktopImage.alt.nohardware'),
          }}
          mobileImage={{
            srcSet: localeImage<Image>(
              images.annotatedImage.mobileImage.image.other,
            ),
            alt: t('annotatedImage.mobileImage.alt.nohardware'),
          }}
          annotations={[
            {
              id: 'annotation-1',
              heading: t('annotatedImage.annotations.annotation1.heading'),
              content: t('annotatedImage.annotations.annotation1.content'),
              positionClasses:
                'top-[3%] right-[79%] sm:top-[15%] sm:right-auto md:right-[76.5%]',
            },
            {
              id: 'annotation-2',
              heading: t(
                'annotatedImage.annotations.annotation2.heading.nohardware',
              ),
              content: t(
                'annotatedImage.annotations.annotation2.content.nohardware',
              ),
              positionClasses:
                'top-[27%] right-[14%] sm:top-auto sm:bottom-[-20px] sm:right-[54%]',
            },
            {
              id: 'annotation-3',
              heading: t('annotatedImage.annotations.annotation3.heading'),
              content: t('annotatedImage.annotations.annotation3.content'),
              positionClasses:
                'top-[67%] right-[52%] sm:top-[20%] sm:right-0 md:top-[15%] md:right-[20%]',
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
        />
      )}
      {isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <PosSectionHeader
          {...t('sectionHeader2')}
          colorScheme={ColorSchemeEnum.PosWarmGray}
          alignment={AlignmentEnum.Left}
          className="[&_h2]:text-t1"
        />
      )}
      {[...posGo].includes(site.locale) && (
        <PosProductDemo
          cards={[
            {
              heading: t('productDemo1.card1.heading'),
              contentHtml: t('productDemo1.card1.contentHtml'),
              image: {
                srcSet: localeImage<Image>(images.productDemo1.card1.image),
                alt: withinUSOrCA
                  ? t('productDemo1.card1.alt.other')
                  : t('productDemo1.card1.alt.default'),
              },
            },
            {
              heading: t('productDemo1.card2.heading'),
              contentHtml: t('productDemo1.card2.contentHtml'),
              image: {
                srcSet: localeImage<Image>(
                  images.productDemo1.card2.image.default,
                ),
                alt: t('productDemo1.card2.alt.default'),
              },
            },
            {
              heading: t('productDemo1.card3.heading'),
              contentHtml: t('productDemo1.card3.contentHtml'),
              image: {
                srcSet: localeImage<Image>(images.productDemo1.card3.image),
                alt: withinUSOrCA
                  ? t('productDemo1.card3.alt.other')
                  : t('productDemo1.card3.alt.default'),
              },
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
          productDemoVariant={ProductDemoVariant.V02}
          alignment={AlignmentEnum.Top}
        />
      )}
      {[...wisePadEng, ...wisePadIntl, ...singapore].includes(site.locale) && (
        <PosProductDemo
          cards={[
            {
              heading: t('productDemo1.card1.heading'),
              contentHtml: t('productDemo1.card1.contentHtml'),
              image: {
                srcSet: localeImage<Image>(images.productDemo1.card1.image),
                alt: withinUSOrCA
                  ? t('productDemo1.card1.alt.other')
                  : t('productDemo1.card1.alt.default'),
              },
            },
            {
              heading: t('productDemo1.card2.heading'),
              contentHtml: t('productDemo1.card2.contentHtml'),
              image: {
                srcSet: localeImage<Image>(
                  images.productDemo1.card2.image.wisepad,
                ),
                alt: t('productDemo1.card2.alt.wisepad'),
              },
            },
            {
              heading: t('productDemo1.card3.heading'),
              contentHtml: t('productDemo1.card3.contentHtml'),
              image: {
                srcSet: localeImage<Image>(images.productDemo1.card3.image),
                alt: withinUSOrCA
                  ? t('productDemo1.card3.alt.other')
                  : t('productDemo1.card3.alt.default'),
              },
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
          productDemoVariant={ProductDemoVariant.V02}
          alignment={AlignmentEnum.Top}
        />
      )}
      <PosSectionHeader
        {...t('sectionHeader3')}
        colorScheme={ColorSchemeEnum.PosLight}
        alignment={AlignmentEnum.Left}
        className="[&_h2]:text-t1"
      />
      {[...posGo].includes(site.locale) && (
        <Cards
          cards={[
            {
              heading: t('cards1.card1.heading'),
              contentHtml: t('cards1.card1.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card1.image),
                alt: t('cards1.card1.alt'),
              },
              link: {
                text: t('cards1.card1.link.text'),
                url: t('cards1.card1.link.url'),
              },
            },
            {
              heading: t('cards1.card2.heading'),
              contentHtml: t('cards1.card2.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card2.image),
                alt: t('cards1.card2.alt'),
              },
              link: ['en'].includes(site.locale)
                ? {
                    text: t('cards1.card2.link.text'),
                    url: t('cards1.card2.link.url'),
                  }
                : undefined,
            },
            {
              heading: t('cards1.card3.heading'),
              contentHtml: t('cards1.card3.content.default'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card3.image.default),
                alt: t('cards1.card3.alt.default'),
              },
            },
          ]}
          className="[&_.link]:text-base"
          colorScheme={ColorSchemeEnum.PosLight}
          headingClasses="pt-6 mt-6 border-t-2 border-shade-90/[0.15] text-t6"
          cardClassName="border-none [&_img]:rounded-none [&_img]:sm:aspect-auto [&_img]:aspect-square [&_img]:sm:object-fill [&_img]:object-cover"
        />
      )}
      {isRetailWisePad3CardReaderAvailable(site.locale) && (
        <Cards
          cards={[
            {
              heading: t('cards1.card1.heading'),
              contentHtml: t('cards1.card1.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card1.image),
                alt: t('cards1.card1.alt'),
              },
              link: {
                text: t('cards1.card1.link.text'),
                url: t('cards1.card1.link.url'),
              },
            },
            {
              heading: t('cards1.card2.heading'),
              contentHtml: t('cards1.card2.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card2.image),
                alt: t('cards1.card2.alt'),
              },
              link: ['en-CA'].includes(site.locale)
                ? {
                    text: t('cards1.card2.link.text'),
                    url: t('cards1.card2.link.url'),
                  }
                : undefined,
            },
            {
              heading: t('cards1.card3.heading'),
              contentHtml: t('cards1.card3.content.default'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card3.image.other),
                alt: t('cards1.card3.alt.other'),
              },
            },
          ]}
          className="[&_.link]:text-base"
          colorScheme={ColorSchemeEnum.PosLight}
          headingClasses="pt-6 mt-6 border-t-2 border-shade-90/[0.15] text-t6"
          cardClassName="border-none [&_img]:rounded-none [&_img]:sm:aspect-auto [&_img]:aspect-square [&_img]:sm:object-fill [&_img]:object-cover"
        />
      )}
      {!isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <Cards
          cards={[
            {
              heading: t('cards1.card1.heading'),
              contentHtml: t('cards1.card1.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card1.image),
                alt: t('cards1.card1.alt'),
              },
              link: {
                text: t('cards1.card1.link.text'),
                url: t('cards1.card1.link.url'),
              },
            },
            {
              heading: t('cards1.card2.heading'),
              contentHtml: t('cards1.card2.content'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card2.image),
                alt: t('cards1.card2.alt'),
              },
            },
            {
              heading: t('cards1.card3.heading'),
              contentHtml: t('cards1.card3.content.nohardware'),
              image: {
                srcSet: localeImage<Image>(images.cards1.card3.image.other),
                alt: t('cards1.card3.alt.other'),
              },
            },
          ]}
          colorScheme={ColorSchemeEnum.PosLight}
          headingClasses="pt-6 mt-6 border-t-2 border-shade-90/[0.15] text-t6"
          cardClassName="border-none [&_img]:rounded-none [&_img]:sm:aspect-auto [&_img]:aspect-square [&_img]:sm:object-fill [&_img]:object-cover"
        />
      )}
      <PosSectionHeader
        {...t('sectionHeader4')}
        colorScheme={ColorSchemeEnum.PosLight}
        alignment={AlignmentEnum.Left}
        className="[&_h2]:text-t3"
      />
      <PosProductDemo
        {...t('productDemo2')}
        cards={t('productDemo2.cards').map((item: any, idx: number) => ({
          ...item,
          image: {
            srcSet: localeImage(images.productDemo2.cards[idx].image),
            alt: item.alt.default
              ? withinUSOrCA
                ? item.alt.other
                : item.alt.default
              : item.alt,
          },
        }))}
        colorScheme={ColorSchemeEnum.PosLight}
        productDemoVariant={ProductDemoVariant.V02}
        alignment={AlignmentEnum.Top}
      />
      {isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <>
          <PosSectionHeader
            {...t('sectionHeader5')}
            colorScheme={ColorSchemeEnum.PosLight}
            alignment={AlignmentEnum.Left}
            className="[&_h2]:text-t1 sm:mb-[70px] mb-[34px]"
            headingWrapperClass={secHeadMaxWidth}
            subheadingWrapperClass={secHeadMaxWidth}
          />
          <PosOutcomes
            {...t('outcomes1')}
            blocks={t('outcomes1.blocks').map((item: any, idx: number) => ({
              ...item,
              icon: {
                src: localeImage(images.outcomes1.blocks[idx].icon),
              },
            }))}
            link={
              isRetailUnifiedFunnelExperiment
                ? {
                    text: t('pos:components.contactSalesCta'),
                    url: '#contact-sales',
                  }
                : {
                    text: t('outcomes1.link.text'),
                    url: values.deeplinkFreeTrial,
                  }
            }
            secondaryLink={
              isRetailSalesAvailable(site.locale) &&
              !isRetailUnifiedFunnelExperiment
                ? {
                    text: t('outcomes1.secondaryLink.text'),
                    url: '#contact-sales',
                  }
                : null
            }
            outcomesVariant={OutcomesVariant.V01}
          />
        </>
      )}
      <SideBySideList
        className="bg-pos-warm-gray pt-2xl mt-2xl md:mt-4xl [&_.text-eyebrow-light-text]:text-lime-70 [&_.mt-4>div]:flex [&_.mt-4>div]:justify-center md:[&_.mt-4>div]:ml-0 md:[&_.mt-4>div]:justify-start"
        mode="light"
        sectionHeader={t('sideBySideList.sectionHeader')}
        sideBySideItems={t('sideBySideList.list').map((item: any) => ({
          headingGroup: {
            headingHtml: item.headingHtml,
            subheadHtml: item.subheadHtml,
          },
        }))}
        image={{
          src: localeImage(images.sideBySideList.image),
          alt: t('sideBySideList.image.alt'),
        }}
        buttonGroup={
          ['en', 'en-CA'].includes(site.locale)
            ? t('sideBySideList.buttonGroup')
            : null
        }
      />
      {isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <PosOutcomes
          {...t('outcomes2')}
          blocks={[
            {
              heading: t('outcomes2.blocks.block1.heading'),
              contentHtml: t('outcomes2.blocks.block1.contentHtml'),
              link: {
                text: t('outcomes2.blocks.block1.link.text'),
                url: localizePath('/pos/omnichannel'),
              },
            },
            {
              heading: t('outcomes2.blocks.block2.heading'),
              contentHtml: t('outcomes2.blocks.block2.contentHtml'),
              link: {
                text: t('outcomes2.blocks.block2.link.text'),
                url: localizePath('/pos/staff-management'),
              },
            },
            {
              heading: t('outcomes2.blocks.block3.heading'),
              contentHtml: t('outcomes2.blocks.block3.contentHtml'),
              link: {
                text: t('outcomes2.blocks.block3.link.text'),
                url: localizePath('/pos/payments'),
              },
            },
            {
              heading: t('outcomes2.blocks.block4.heading'),
              contentHtml: t('outcomes2.blocks.block4.contentHtml'),
            },
            {
              heading: t('outcomes2.blocks.block5.heading'),
              contentHtml: t('outcomes2.blocks.block5.contentHtml'),
            },
            {
              heading: t('outcomes2.blocks.block6.heading'),
              contentHtml: t('outcomes2.blocks.block6.contentHtml'),
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
          className="[&_p]:max-w-[725px]"
          headingClass={`${secHeadMaxWidth} md:text-t1`}
          subheadingWrapperClass={secHeadMaxWidth}
        />
      )}
      {!isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <PosOutcomes
          {...t('outcomes2')}
          blocks={[
            {
              heading: t('outcomes2.blocks.block1.heading'),
              contentHtml: t('outcomes2.blocks.block1.contentHtml'),
              link: {
                text: t('outcomes2.blocks.block1.link.text'),
                url: localizePath('/pos/omnichannel'),
              },
            },
            {
              heading: t('outcomes2.blocks.block2.heading'),
              contentHtml: t('outcomes2.blocks.block2.contentHtml'),
              link: {
                text: t('outcomes2.blocks.block2.link.text'),
                url: localizePath('/pos/staff-management'),
              },
            },
            {
              heading: t('outcomes2.blocks.block4.heading'),
              contentHtml: t('outcomes2.blocks.block4.contentHtml'),
            },
            {
              heading: t('outcomes2.blocks.block5.heading'),
              contentHtml: t('outcomes2.blocks.block5.contentHtml'),
            },
            {
              heading: t('outcomes2.blocks.block6.heading'),
              contentHtml: t('outcomes2.blocks.block6.contentHtml'),
            },
          ]}
          colorScheme={ColorSchemeEnum.PosWarmGray}
          className="[&_p]:max-w-[725px]"
          headingClass={`${secHeadMaxWidth} md:text-t1`}
          subheadingWrapperClass={secHeadMaxWidth}
        />
      )}
      {isRetailHardwareAndPaymentsAvailable(site.locale) && (
        <Section mode="light">
          <SectionHeader
            {...t('hardwareSectionHeader')}
            mode="light"
            className="[&_.text-eyebrow-light-text]:text-lime-70"
          />
          <div className="container">
            <HardwareImage
              alt={
                isRetailPosGoAvailable(site.locale)
                  ? t('hardwareImage.image.alt.default')
                  : t('hardwareImage.image.alt.wisepad')
              }
              src={localeImage(images.hardwareImage.image)}
              className="w-full"
            />
          </div>
          <CardGrid withContainer={true} numberOfColumns={3}>
            {t('hardwareCards').map((card: any, idx: number) => {
              return (
                <Card
                  {...card}
                  type="topDivider"
                  key={`${idx}-${card.headingGroup.headingHtml}`}
                  size="t3"
                  className="border-shade-90/[0.15]"
                />
              );
            })}
          </CardGrid>
          <div className="container flex justify-center">
            <Button
              href={t('hardwareButton.link.url')}
              intent="secondary"
              mode="light"
            >
              {t('hardwareButton.link.text')}
            </Button>
          </div>
        </Section>
      )}
      {isRetailDemoVideoAvailable(site.locale) && (
        <>
          <Conversion
            {...t('pricingConversion')}
            buttons={t('pricingConversion.buttons')}
            mode="light"
            className="mb-2xl"
          />
          <Section
            topSpacing="2xl"
            bottomSpacing="2xl"
            className="bg-separate-middle from-white to-shade-90"
          >
            <FullWidthCard
              image={{
                src: images.fullWidthCard.image,
                alt: t('fullWidthCard.image.alt'),
                loading: 'lazy',
              }}
              mode="light"
              visualPosition="left"
              className="bg-lime-90"
            >
              <Typography as="h3" isRichtext={false} size="t3" mode="dark">
                {t('fullWidthCard.headingHtml')}
              </Typography>
              <Typography as="p" isRichtext={false} size="p" mode="dark">
                {t('fullWidthCard.subheadHtml')}
              </Typography>
              <Button
                external
                className="w-max"
                href={settings('links.fullWidthCard.link.href')}
                intent="secondary"
                mode="dark"
              >
                {t('fullWidthCard.link.text')}
              </Button>
            </FullWidthCard>
          </Section>
        </>
      )}
      {!isRetailDemoVideoAvailable(site.locale) && (
        <PosProductDemo
          {...t('productDemo3')}
          image={{
            alt: localizedAltText(),
            srcSet: images.productDemo3.image[site.locale],
          }}
          productDemoVariant={ProductDemoVariant.AllProducts}
          allProductsDemoClass="text-white"
          className="[&_img]:md:h-[500px] bg-separate-middle from-white to-shade-90 !pt-0"
          alignment={AlignmentEnum.Top}
          colorScheme={ColorSchemeEnum.PosWarmGray}
        />
      )}
      {isRetailBlogAvailable(site.locale) && (
        <>
          <PosSectionHeader
            {...t('sectionHeader8')}
            colorScheme={ColorSchemeEnum.PosDark}
            alignment={AlignmentEnum.Left}
            className="[&_h2]:text-t1"
            subheadingWrapperClass={secHeadMaxWidth}
          />
          <Cards
            cards={[
              {
                heading: t('cards2.card1.heading'),
                contentHtml: t('cards2.card1.content'),
                image: {
                  srcSet: localeImage<Image>(images.cards2.card1.image.default),
                  alt: t('cards2.card1.alt.other'),
                },
                link: {
                  text: t('cards2.card1.link.text'),
                  url: ['en'].includes(site.locale)
                    ? localizePath('/retail/mobile-pos-system')
                    : localizePath('/retail/point-of-sale-system'),
                },
              },
              {
                heading: t('cards2.card2.heading'),
                contentHtml: t('cards2.card2.content'),
                image: {
                  srcSet: localeImage<Image>(images.cards2.card2.image),
                  alt: t('cards2.card2.alt'),
                },
                link: {
                  text: t('cards2.card2.link.text'),
                  url: localizePath('/retail/pos-software'),
                },
              },
              {
                heading: t('cards2.card3.heading'),
                contentHtml: t('cards2.card3.content'),
                image: {
                  srcSet: localeImage<Image>(images.cards2.card3.image),
                  alt: t('cards2.card3.alt'),
                },
                link: {
                  text: t('cards2.card3.link.text'),
                  url: localizePath('/retail/best-pos-system'),
                },
              },
            ]}
            button={{
              text: t('cards2.button.text'),
              url: localizePath('/retail/'),
            }}
            colorScheme={ColorSchemeEnum.PosDark}
            headingClasses="text-t6"
            cardClassName="border-shade-70 [&_img]:rounded-none"
          />
        </>
      )}
      {['en'].includes(site.locale) ? (
        <PosFAQ
          heading={settings('faqWithVideo.heading')}
          questions={settings('faqWithVideo.questions')}
          colorScheme={ColorSchemeEnum.PosDark}
          className="[&_h2]:text-t3"
        />
      ) : (
        <PosFAQ
          heading={t('faq.heading')}
          questions={t('faq.questions')}
          colorScheme={ColorSchemeEnum.PosDark}
          className="[&_h2]:text-t3"
        />
      )}
      {isRetailHardwareAndPaymentsAvailable(site.locale) ? (
        <PosConversion
          {...t('conversion')}
          cta={{
            buttonPrimary: isRetailUnifiedFunnelExperiment
              ? {
                  text: t('pos:components.contactSalesCta'),
                  url: '#contact-sales',
                }
              : {
                  text: t('conversion.cta.buttonPrimary.text'),
                  url: values.deeplinkFreeTrial,
                },
            buttonSecondary:
              isRetailSalesAvailable(site.locale) &&
              !isRetailUnifiedFunnelExperiment
                ? {
                    text: t('conversion.cta.buttonSecondary.text'),
                    url: '#contact-sales',
                  }
                : null,
            ctaDisclaimer: {
              contentHtml: t('conversion.cta.ctaDisclaimer.contentHtml'),
            },
          }}
          colorScheme={ColorSchemeEnum.PosDark}
          className="bg-lime-90 text-white"
          buttonClass="sm:mb-0 mb-[20px] block"
        />
      ) : (
        <PosConversion
          {...t('conversion')}
          cta={{
            buttonPrimary: {
              text: t('conversion.cta.buttonPrimary.text'),
              url: values.deeplinkFreeTrial,
            },
            ctaDisclaimer: {
              contentHtml: t('conversion.cta.ctaDisclaimer.contentHtml'),
            },
          }}
          colorScheme={ColorSchemeEnum.PosDark}
          className="bg-lime-90 text-white"
        />
      )}
      <ShopifyPOSSchema />
    </>
  );
}
